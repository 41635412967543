export const baseObjects = [
  { object: 'fulano', class: 'Pessoa' },
  { object: 'sicrana', class: 'Pessoa' },
  { object: 'beltrano', class: 'Pessoa' },
  { object: 'moacyr', class: 'Construtor' },
  { object: 'bob', class: 'Construtor' },
  { object: 'jonathan', class: 'Construtor' },
  { object: 'chimbinha', class: 'Músico' },
  { object: 'riquelme', class: 'Músico' },
  { object: 'belMarques', class: 'Músico' },
  { object: 'vermelho', class: 'Cor' },
  { object: 'azul', class: 'Cor' },
  { object: 'amarelo', class: 'Cor' },
  { object: 'barraquinha', class: 'Loja' },
  { object: 'camelô', class: 'Loja' },
  { object: 'livraria', class: 'Loja' },
  { object: 'foxtrot', class: 'LojaInstrumento' },
  { object: 'trêsTons', class: 'LojaInstrumento' },
  { object: 'ritmus', class: 'LojaInstrumento' },
  { object: 'temTudo', class: 'LojaConstrucao' },
  { object: 'comercial', class: 'LojaConstrucao' },
  { object: 'apolo', class: 'LojaConstrucao' },
  { object: 'garfo', class: 'Objeto' },
  { object: 'vaso', class: 'Objeto' },
  { object: 'mesa', class: 'Objeto' },
  { object: 'guitarra', class: 'Instrumento' },
  { object: 'baixo', class: 'Instrumento' },
  { object: 'bateria', class: 'Instrumento' },
  { object: 'chaveFenda', class: 'Ferramenta' },
  { object: 'furadeira', class: 'Ferramenta' },
  { object: 'alicate', class: 'Ferramenta' },
];
